import React, { useState, useEffect, useContext } from "react";
import { bytestoMB } from "./Conversions";
import { RunSummaryContextNV } from "../Contexts/RunSummaryContextNV";
import * as API from "../Services/ApiService/GetRunDetails";
import { elementTooltip } from "../Common/Utilities";

function RunLogs(props) {
  const [logs, setLogs] = useState([]);
  const { RunId } = useContext(RunSummaryContextNV);
  let currentCycle = -1;
  useEffect(async () => {
    try {
      const res = await API.GetRunsLogs(RunId);
      let orderedLogs = res.data.sort((x, y) => x.Cycle - y.Cycle);
      if (orderedLogs.length) props.SetHasLogs(true);
      setLogs(orderedLogs);
    } catch (e) {}
  }, [RunId]);

  const handleDownload = (row) => {
    const link = document.createElement("a");
    link.href = row.SasUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function getLogRow(l) {
    if (currentCycle !== -1) {
      if (currentCycle !== parseInt(l.Cycle)) {
        currentCycle = parseInt(l.Cycle);
        return (
          <React.Fragment>
            <span tabindex="0">
              <img
                title="Reboot"
                class="smalliconsize"
                src="/e7e8.png"
                alt="reboot"
              />
              Reboot
            </span>
            <p className="ellipsis">
              <a onClick={() => handleDownload(l)} data-toggle="tooltip">
                <span
                  data-toggle="tooltip"
                  title={elementTooltip(l.Name, l.Annotation)}
                >
                  {l.DisplayName}{" "}
                </span>
              </a>
              <span style={{ color: "black" }}>
                ({l.Extension}, {bytestoMB(l.SizeBytes)})
              </span>
            </p>
          </React.Fragment>
        );
      } else {
        currentCycle = parseInt(l.Cycle);
        return (
          <p className="ellipsis">
            <a onClick={() => handleDownload(l)} data-toggle="tooltip">
              <span
                data-toggle="tooltip"
                title={elementTooltip(l.Name, l.Annotation)}
              >
                {l.DisplayName}{" "}
              </span>
            </a>
            <span style={{ color: "black" }}>
              ({l.Extension}, {bytestoMB(l.SizeBytes)})
            </span>
          </p>
        );
      }
    } else {
      currentCycle = parseInt(l.Cycle);
      return (
        <p className="ellipsis">
          <a onClick={() => handleDownload(l)} data-toggle="tooltip">
            <span
              data-toggle="tooltip"
              title={elementTooltip(l.Name, l.Annotation)}
            >
              {l.DisplayName}{" "}
            </span>
          </a>
          <span style={{ color: "black" }}>
            ({l.Extension}, {bytestoMB(l.SizeBytes)})
          </span>
        </p>
      );
    }
  }

  return (
    <React.Fragment>
      <p className="drawerheaderstyle">Logs</p>
      <div style={{ marginTop: "12px" }} id="menu">
        {logs ? logs.map((l) => getLogRow(l)) : <p></p>}
      </div>
    </React.Fragment>
  );
}
export default RunLogs;
