/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import { feedBackDefaultValues, feedBackMessages } from '../../Constants/feedback';
import { IncidentFeedBackApiModel, WorkItemFeedBackApiModel } from '../../Models/feedbackApiModel';
import { IFeedBackWindowInput } from '../../Models/feedbackWindowItem';
import { CreateVsoTask, CreateIcmIncident } from '../../Services/ApiService/FeedbackApi';
import '../../Styles/feedback.scss';
import '@progress/kendo-theme-material/dist/all.scss';
import '../../Styles/tool.scss';

export const FeedbackWindow: React.FC<IFeedBackWindowInput> = ({
    item,
    setLoading,
    createWorkItemId,
    createIcmIncidentId,
    setShowFeedWindow,
}) => {
    const [Title, setTitle] = useState(feedBackDefaultValues.TitleDefaultValue);
    const [Description, setDescription] = useState(feedBackDefaultValues.DescriptionDefaultValue);
    const [SelectedFile, setSelectedFile] = React.useState<File>();
    const [BaseImage, setBaseImage] = React.useState<string>();
    const [Submit, setSubmit] = React.useState<boolean>(false);
    const [AttachedFileName, setAttachedFileName] = useState('');
    const [SubmitButtonEnabled, setSubmitButtonEnabled] = useState(true);
    const [SuggestionRadioButtonSelected, setSuggestionRadioButtonSelected] = useState(true);
    const inputFile = useRef<HTMLInputElement>(null);
    const PasteImageSupported: boolean =
        feedBackDefaultValues.PasteImageSupported &&
        navigator.userAgent.indexOf('Trident') === -1 &&
        navigator.userAgent.indexOf('MSIE') === -1;
    const PasteImageText = PasteImageSupported
        ? feedBackMessages.PasteImageSupportedText
        : feedBackMessages.PasteImageNotSupportedText;

    //process for VSO or IcM
    React.useEffect(() => {
        if (!Submit) {
            return;
        }
        setLoading(true);
        //send suggestion to create vso task
        const asyncCallback = async () => {
            if (SuggestionRadioButtonSelected) {
                await submitVSOTask();
            } else {
                //send incident report to create IcM incident
                await submitIcMIncident();
            }
        };
        asyncCallback();
        setSubmit(false);
        if (item) {
            item.handleOpen();
        }
        // setLoading(false);
    }, [Submit]);

    //send suggestion to create vso task
    const submitVSOTask = async () => {
        console.log('came in for');
        const params = {
            Title: Title,
            Desc: Description,
            Report: item.reportName,
            Base64Image: BaseImage,
        } as WorkItemFeedBackApiModel;

        try {
            const result = await CreateVsoTask(params, item.apiBaseUrl);
            setLoading(false);

            if (result.data) {
                createWorkItemId(result.data);
                createIcmIncidentId(undefined);
                setShowFeedWindow(true);
            }
        } catch (er) {
            setLoading(false);
            alert('Error in creating FeedBack Work Item, Please try again later');
        }
    };

    //send suggestion to create icm incident
    const submitIcMIncident = async () => {
        const params = {
            Title: Title,
            Desc: Description,
            Report: item.reportName,
            Base64Image: BaseImage,
            Base64ImageName: AttachedFileName,
        } as IncidentFeedBackApiModel;

        try {
            const result = await CreateIcmIncident(params, item.apiBaseUrl);
            setLoading(false);

            if (result.data) {
                createIcmIncidentId(result.data);
                createWorkItemId(undefined);
                setShowFeedWindow(true);
            }
        } catch (er) {
            setLoading(false);
            alert('Error in creating FeedBack Incident, Please try again later');
        }
    };

    const attachImageAction = () => {
        if (inputFile != null && inputFile.current != null) {
            inputFile.current.click();
        }
    };

    //validations
    const validateInput = async () => {
        const titleVal = Title.trim();
        const descVal = Description.trim();

        //test if title is empty
        if (titleVal === '') {
            alert(feedBackMessages.TitleMissingAlertMessage);
            return false;
        }
        //test if desc is empty
        else if (descVal === '') {
            alert(feedBackMessages.DescriptionMissingTextMessage);
            return false;
        } else {
            setSubmitButtonEnabled(false);
            processAttachment();
            setSubmitButtonEnabled(true);
            item.handleOpen();
        }
    };

    const removeFile = () => {
        setSelectedFile(undefined);
    };

    React.useEffect(() => {
        if (SelectedFile == null) {
            return;
        }
        if (SelectedFile.type !== 'image/png') {
            alert(feedBackMessages.OnlyPngSupportedAlertText);
            removeFile();
            return;
        }
    }, [SelectedFile]);

    //changes when file added or removed
    const fileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        if (!fileList) return;
        setSelectedFile(fileList[0]);
    };

    //handle if image is pasted, file uploaded or neither
    const processAttachment = () => {
        if (SelectedFile != null) {
            convertToBase64AndSubmitFeedBack();
        }
        //no attachment, will be sent to submitfeedback
        else {
            //no files
            setAttachedFileName('none');
            setBaseImage('none');
            setSubmit(true);
        }
    };

    //convert uploaded file
    const convertToBase64AndSubmitFeedBack = () => {
        //get files from upload
        //get first file (only file for now)
        const file = SelectedFile;
        if (file == null) {
            return;
        }
        //read file
        const reader = new FileReader();
        reader.readAsDataURL(file);
        //save file base image
        reader.onloadend = function () {
            const data = reader.result as string;
            if (data != null) {
                setAttachedFileName(file.name);
                setBaseImage(data.split('base64,')[1]);
                setSubmit(true);
            }
        };
        reader.onerror = function () {
            alert(feedBackMessages.ImageConversionError);
            return;
        };
    };

    const ImageInformation = () => {
        return (
            <>
                {SelectedFile != null ? (
                    <>
                        ({SelectedFile.name} is attached. )
                        <button className="xBtn" onClick={removeFile}>
                            X
                        </button>
                    </>
                ) : (
                    <p>{PasteImageText}</p>
                )}
            </>
        );
    };

    const handlePaste = (event: any) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        // find pasted image among pasted items
        let blob = null;
        for (const element of items) {
            if (element.type.indexOf('image') === 0) {
                blob = element.getAsFile();
            }
        }
        if (blob !== null) {
            setSelectedFile(blob);
        }
    };

    const FeedbackWindow = () => {
        return (
            <div id="feedbackWindow" className="feedbackwindowItem k-window-content k-content">
                <input
                    type="radio"
                    name="inputType"
                    id="suggestion"
                    className="feedback-radio"
                    checked={SuggestionRadioButtonSelected}
                    onChange={() => setSuggestionRadioButtonSelected(true)}
                />
                <label title="This will create a VSO task." className="feedback-radio-label" htmlFor="suggestion">
                    Submit Suggestion/Question
                </label>
                <input
                    type="radio"
                    name="inputType"
                    id="incident"
                    className="feedback-radio"
                    onChange={() => setSuggestionRadioButtonSelected(false)}
                    checked={!SuggestionRadioButtonSelected}
                />
                <label title="This will create an IcM incident." className="feedback-radio-label" htmlFor="incident">
                    Report Incident
                </label>
                <input
                    type="text"
                    placeholder="Title"
                    name="title"
                    id="feedbackTitle"
                    onInput={(e) => setTitle((e.target as HTMLInputElement).value)}
                />
                <textarea
                    id="feedbackDesc"
                    placeholder="Enter comments here"
                    onInput={(e) => setDescription((e.target as HTMLInputElement).value)}
                    onPaste={handlePaste}
                ></textarea>
                <div id="pasteAndUpload">
                    <div id="pasteTarget"> {ImageInformation()}</div>
                    <div id="noPaste"></div>
                    <input
                        name="files"
                        id="files"
                        ref={inputFile}
                        onChange={fileChange}
                        className="feedbackWindowbtns"
                        type="file"
                        aria-label="files"
                    />
                    <div className="styledUploadBtn">
                        <button
                            className="k-button feedbackWindowbtns"
                            id="attachFileBtn"
                            type="button"
                            onClick={attachImageAction}
                        >
                            Attach an Image
                        </button>
                    </div>
                </div>
                <div id="feedbackBtns">
                    <button
                        className="feedbackBtns k-button feedbackWindowbtns"
                        id="submitBtn"
                        onClick={validateInput}
                        disabled={!SubmitButtonEnabled}
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    };

    return <>{FeedbackWindow()}</>;
};
